import React, {useEffect, useRef} from "react"
import styled from "styled-components"
import {Header} from "../common/Header";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import {useDispatch, useSelector} from "react-redux"
import {setFormValueAction} from "../../state/form";
import CustomDatePicker from "../common/CustomDatepicker";
import {ContentWrapper} from "../common/ContentWrapper";
import EditableTextInput from "../common/EditableTextInput";
import {ErrorMessage, Formik} from "formik";
import {navigate} from "gatsby";
import {ErrorMessageText, GreenHint, TabWrapper} from "../firstStepFirstTab/FirstStepFirstTab";
import {NavigationButtonsWrapper} from "../common/NavigationButtonsWrapper";
import {CategoryHeader} from "../common/CategoryHeader";
import RadioButtonTwoOptions from "../common/SingleRadioButtonTwoOptions";
import {RadioButtonsTwoOptionsWrapper} from "../firstStepSecondTab/FirstStepSecondTab";
import {fetchYesOrNoAction} from "../../state/yesOrNo";
import {setFirstStepThirdTabFilled} from "../../state/setPagesAreFilled"
import {fetchCitiesAction} from "../../state/cities";

const SingleRadioButtonWrapper = styled.div`
	margin: 30px 0 -10px;
	position: relative;
	@media(min-width: 1280px) {
		margin: 50px 0 0px;
		&.second-item {
			margin: 0 0 -20px;
		}
	}
	&.second-item {
		margin: 0 0 -30px;
		p {
			bottom: 25px;
		}
	}
`

const FirstStepThirdTab = () => {
	const dispatch = useDispatch();
	const formReducer = useSelector(state => state.form);
	const yesOrNoReducer = useSelector(state => state.yesOrNo);
	const townReducer = useSelector(state => state.cities);
	const formikRef = useRef(null);
	const city = townReducer.data.city;

	useEffect(() => {
		dispatch(fetchYesOrNoAction());
	}, []);

	useEffect(() => {
		if (city) {
			dispatch(setFormValueAction('city', city));
			formikRef.current.setFieldValue('city', city)
		}
	}, [city]);

	const currentDate = new Date();
	const calendarDateFormat = "dd/MM/yyyy";

	const handleValidation = values => {
		const errors = {};
		if (!values.vehicleMileage) {
			errors.vehicleMileage = 'pole wymagane';
		}
		if (!values.isTechCheckValid) {
			errors.isTechCheckValid = 'pole wymagane'
		}
		if (!values.firstRegistrationDate) {
			errors.firstRegistrationDate = 'pole wymagane'
		}
		if (values.firstRegistrationDate) {
			const registrationDateStringified = values.firstRegistrationDate.toString()
			const registrationDateAsDate = new Date(registrationDateStringified)
			const productionYearStringified = formReducer.productionYearLabel.toString();
			const productionYearAsDate = new Date(productionYearStringified)
			const isDateValid = (registrationDateAsDate >= productionYearAsDate)
			if (!isDateValid) {
				errors.firstRegistrationDate = 'rok rejestracji musi być większy lub równy rokowi produkcji'
			}
		}
		if (!values.vehiclePurchaseDate) {
			errors.vehiclePurchaseDate = 'pole wymagane'
		}
		if (!values.estimatedMileage) {
			errors.estimatedMileage = 'pole wymagane'
		}
		if (!values.zipCode) {
			errors.zipCode = 'pole wymagane'
		}
		if (!values.city) {
			errors.city = 'wprowadź poprawny kod pocztowy'
		}
		return errors;
	};

	return (
		<TabWrapper>
			<Header className="tablet-hidden">Dane pojazdu</Header>
			<GreenHint className="tablet-hidden">dane zakupu</GreenHint>
			<ContentWrapper>
				<Formik initialValues={{...formReducer}}
				        validate={handleValidation}
				        innerRef={formikRef}
					    onSubmit={(values, {setSubmitting}) => {
						    setSubmitting(false);
						    dispatch(setFormValueAction("vehiclePurchaseDate", values.vehiclePurchaseDate))
						    dispatch(setFormValueAction("firstRegistrationDate", values.firstRegistrationDate))
						    dispatch(setFormValueAction("isCoOwner", values.isCoOwner));
						    dispatch(setFormValueAction("icCoOwnerLabel", (values.isCoOwner ? "tak" : "nie")))
						    dispatch(setFormValueAction("isTechCheckValid", values.isTechCheckValid))
						    dispatch(setFormValueAction("isTechCheckValidLabel", (values.isTechCheckValid ? "tak" : "nie")))
						    dispatch(setFormValueAction("vehicleMileage", values.vehicleMileage))
						    dispatch(setFormValueAction("estimatedMileage", values.estimatedMileage))
						    dispatch(setFormValueAction("zipCode", values.zipCode))
						    dispatch(setFormValueAction("city", values.city))
						    navigate('/step-2')
						    dispatch(setFirstStepThirdTabFilled(true))
					    }}>
					{({values, errors, isSubmitting, touched, handleChange, handleBlur, handleSubmit, setFieldValue}) => (
						<form onSubmit={handleSubmit}>
							<CustomDatePicker label={"Data zakupu:"}
							                  type="date"
							                  placeholderText={"wybierz datę zakupu"}
							                  name="vehiclePurchaseDate"
							                  required
							                  selected={values.vehiclePurchaseDate}
							                  onChange={(val) => setFieldValue("vehiclePurchaseDate", val)}
							                  maxDate={currentDate}
							                  showMonthDropdown
							                  showYearDropdown
							                  dropdownMode="scroll"
							                  locale={'pl'}
							                  dateFormat={calendarDateFormat}
							                  pickerClassName="datepicker first-component"/>
							<SingleRadioButtonWrapper>
								<CategoryHeader>Czy samochód posiada współwłaściciela?</CategoryHeader>
								<RadioButtonsTwoOptionsWrapper>
									{yesOrNoReducer.data.map(({label, value}) => (
										<RadioButtonTwoOptions id={value}
										                       key={value}
										                       activeId={values.isCoOwner}
										                       amountName={label}
										                       onClick={(val) => {
											                       setFieldValue("isCoOwner", val)
										                       }}/>
									))}
								</RadioButtonsTwoOptionsWrapper>
							</SingleRadioButtonWrapper>
							<SingleRadioButtonWrapper className="second-item">
								<CategoryHeader>Czy samochód posiada ważne badanie techniczne?</CategoryHeader>
								<RadioButtonsTwoOptionsWrapper>
									{yesOrNoReducer.data.map(({label, value}) => (
										<RadioButtonTwoOptions id={value}
										                       key={value}
										                       activeId={values.isTechCheckValid}
										                       amountName={label}
										                       onClick={(val) => {
											                       setFieldValue("isTechCheckValid", val)
										                       }}/>
									))}
								</RadioButtonsTwoOptionsWrapper>
								<ErrorMessageText><ErrorMessage name="isTechCheckValid"/></ErrorMessageText>
							</SingleRadioButtonWrapper>
							<CustomDatePicker label={"Data pierwszej rejestracji:"}
							                  type="date"
							                  placeholderText={"wybierz datę"}
							                  name="firstRegistrationDate"
							                  required
							                  selected={values.firstRegistrationDate}
							                  onChange={(val) => setFieldValue("firstRegistrationDate", val)}
							                  maxDate={currentDate}
							                  showMonthDropdown
							                  showYearDropdown
							                  dropdownMode="scroll"
							                  locale={'pl'}
							                  dateFormat={calendarDateFormat}
							                  pickerClassName="datepicker"/>
							<ErrorMessageText><ErrorMessage name="firstRegistrationDate"/></ErrorMessageText>
							<EditableTextInput editableInputHeader={"Aktualny przebieg"} placeholder={"wpisz wartość"}
							                   onChange={handleChange} type="number" name="vehicleMileage"
							                   unitName="tys. km"
							                   value={values.vehicleMileage}/>
							<ErrorMessageText><ErrorMessage name="vehicleMileage"/></ErrorMessageText>
							<EditableTextInput editableInputHeader={"Szacowany przebieg roczny"}
							                   placeholder={"wpisz wartość"} unitName="tys. km"
							                   onChange={handleChange} type="number" name="estimatedMileage"
							                   value={values.estimatedMileage}/>
							<ErrorMessageText><ErrorMessage name="estimatedMileage"/></ErrorMessageText>
							<EditableTextInput editableInputHeader={"Kod pocztowy"} placeholder={"__-___"}
							                   onChange={(event) => {
								                   handleChange(event);
								                   const inputValue = event.target.value;
								                   const re = /^[0-9]{2}-[0-9]{3}$/;
								                   if (re.test(inputValue)) {
									                   const parsedZipCode = inputValue.replace('-', '');
									                   dispatch(fetchCitiesAction(parsedZipCode));
								                   }
							                   }}
							                   type="text"
							                   name="zipCode"
							                   value={values.zipCode}
							                   isZipCodeMask={true}/>
							<ErrorMessageText><ErrorMessage name="zipCode"/></ErrorMessageText>
							<EditableTextInput editableInputHeader={"Miejscowość"} isCity={true}
							                   placeholder={"wpisz nazwę miejscowości"} isValidationError={errors.city}
							                   onChange={handleChange} type="text" name="city" value={values.city}/>
							<ErrorMessageText><ErrorMessage name="city"/></ErrorMessageText>
							<NavigationButtonsWrapper>
								<PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"}
								                    to="/step-1-2"/>
								<NextStepButton type="submit"
								                isDisabled={isSubmitting}
								                isVisible={true}
								                textBtn={"DALEJ"}/>
							</NavigationButtonsWrapper>
						</form>)}
				</Formik>
			</ContentWrapper>
		</TabWrapper>
	)
};

export default FirstStepThirdTab;