import React from "react"
import FirstStepThirdTab from "../components/firstStepThirdTab/FirstStepThirdTab";

const Step1Tab3 = () => {
	return (
		<>
			<FirstStepThirdTab/>
		</>
	)
};

export default Step1Tab3
